<template>
  <div class="JustifiedContingencie">
    <q-dialog v-model="opened" class="q-pa-none">
      <q-card class="q-mx-xl" style="width: 700px; max-width: 80vw;">
        <q-card-section class="text-left text-bold">
          <span class="text-h5">{{
            `Contingência: ${request.contingencie}`
          }}</span>
        </q-card-section>
        <q-card-section class="q-pa-lg q-ma-none">
          <span class="">Favor informar o justificativa da </span>
          <span class="text-bold" :class="classJustified">{{
            approvalType
          }}</span>
          <q-input outlined v-model="justified" type="textarea" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn label="Cancelar" color="grey" v-close-popup />
          <q-btn
            label="Justificar"
            :color="colorBtn"
            @click="onJustifiedContingencie()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import removeCharSpecial from "../../helpers/removerCharSpecial";

export default {
  data() {
    return {
      opened: false,
      justified: "",
      approvalType: "",
      colorBtn: "primary",
      classJustified: "",
      request: "",
      response: null
    };
  },
  methods: {
    ...mapActions("contingencies", [
      "getContingencies",
      "approvesRejectsJustifysContingencies"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    open(request, response, text, classMessage) {
      text === "APROVAR"
        ? (this.approvalType = "APROVAÇÃO")
        : (this.approvalType = "REJEIÇÃO");
      text === "APROVAR" ? (this.colorBtn = "green") : (this.colorBtn = "red");

      this.classJustified = classMessage;

      this.request = request;
      this.response = response;

      this.opened = true;
    },
    async onJustifiedContingencie() {
      try {
        if (String(this.justified).trim().length < 5) {
          this.$q.dialog({
            title: "Justificativa Inválida",
            message: "Favor informar a justificativa...",
            position: "standard",
            ok: false
          });

          return null;
        }

        await this.approvesRejectsJustifysContingencies({
          request: this.request,
          response: this.response,
          reason: removeCharSpecial(this.justified)
        });
        this.setNotification({
          message: "Resposta enviada com sucesso.",
          color: "green",
          position: "top"
        });

        this.justified = "";
        this.opened = false;
        this.getContingencies();
        this.$router.push({ name: "contingencies" });
      } catch (error) {
        console.log(error);
        this.setNotification({
          message: error.message,
          color: "red",
          position: "top"
        });
      }
    }
  }
};
</script>
