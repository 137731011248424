<template>
  <div class="ContingencieDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />

          <q-btn icon="announcement" class="buttonsBar" @click="openDetails" />

          <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            v-else
            icon="table_chart"
            class="buttonsBar"
            @click="viewTable = !viewTable"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: calc(100vh - 190px)">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="contingencie">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Contingência: </span>
                <span>{{ contingencie.contingencie }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Tipo: </span>
                <span>{{
                  `${contingencie.documentNumber} - ${contingencie.type}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Empresa: </span>
                <span>{{
                  `${contingencie.branchCode} - ${contingencie.branchName}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Municipio: </span>
                <span>{{
                  `${contingencie.branchCity || ""} - ${contingencie.branchUf ||
                    ""}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Dt. Conting.: </span>
                <span>{{ formatStg(contingencie.issueDate, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Validade: </span>
                <span>{{ formatStg(contingencie.validate, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Solicitante: </span>
                <span>{{ contingencie.requester }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Conta: </span>
                <span>{{ `${contingencie.co} - ${contingencie.coName}` }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Classe: </span>
                <span>{{
                  `${contingencie.classe} - ${contingencie.classeName}`
                }}</span>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor: </span>
                <span>{{ formatStg(contingencie.value, "currency") }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
        <DataTable
          v-if="contingencie"
          :data="contingencie.productData.items"
          :columns="columns"
          :gridMobile="false"
          hidePagination
          class="q-mx-sm"
          style="margin-bottom: 50px ;"
        />
        <!-- :currency="contingencie.currency" -->
      </div>

      <div v-else>
        <productsCard :data="contingencie" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn
          class="q-ma-xs"
          color="green"
          icon="check"
          label="Aprovar"
          @click="approve"
        />
      </div>
      <div class="col-auto">
        <q-btn
          class="q-ma-xs"
          color="red"
          icon="block"
          label="Rejeitar"
          @click="reject"
        />
      </div>
      <div class="col-auto" v-if="contingencie.response === 'pending'">
        <q-btn
          class="q-ma-xs"
          color="blue-8"
          icon="chat_bubble_outline"
          label="Confirmar Transf."
          v-show="contingencie.level === '01' ? true : false"
          @click="cancel"
        />
      </div>
      <div class="col-auto" v-else>
        <q-btn
          class="q-ma-xs"
          color="orange"
          icon="chat_bubble_outline"
          label="Transferir"
          v-show="contingencie.level === '01' ? true : false"
          @click="justify"
        />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />
    <ApprovalDetails ref="ApprovalDetails" />
    <JustifiedContingencie ref="JustifiedContingencie" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import productsCard from "./productsCard";
import DataTable from "../../components/DataTable";
import ApprovalHierarchy from "./ApprovalHierarchy";
import ApprovalDetails from "./ApprovalDetails.vue";
import JustifiedContingencie from "./justifiedContingencie.vue";
import formatString from "@/helpers/formatString";

export default {
  components: {
    productsCard,
    DataTable,
    ApprovalHierarchy,
    ApprovalDetails,
    JustifiedContingencie
  },
  data() {
    return {
      contingencie: {},
      viewTable: false,
      columns: [
        {
          field: "item",
          label: "Item",
          align: "center",
          sortable: true
        },
        {
          field: "productCode",
          label: "Cód. Item",
          align: "center",
          sortable: true
        },
        {
          field: "productDescription",
          label: "Descrição Item",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "um",
          label: "UM",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "quantity",
          label: "Qt.",
          align: "right",
          type: "number",
          sortable: true
        },
        {
          field: "unitaryValue",
          label: "Vlr.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "unitaryDiscount",
          label: "Desc.Unit.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "amount",
          label: "Vlr.Tot.",
          align: "right",
          type: "currency",
          sortable: true
        },
        {
          field: "deliveryDate",
          label: "Dt. Entrega",
          align: "center",
          type: "date",
          sortable: true
        },
        {
          field: "note",
          label: "Obs.",
          align: "left",
          type: "string",
          sortable: true
        }
      ]
    };
  },
  computed: {
    contingencieId() {
      return this.$route.params.contingencieId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("contingencies", [
      "getContingencieById",
      "approvesRejectsJustifysContingencies"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    formatStg(value, type, decimals) {
      return formatString(value, type, decimals);
    },
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.contingencie.approvalHierarchy);
    },
    openDetails() {
      this.$refs.ApprovalDetails.open(this.contingencie.detail);
    },
    approve() {
      this.sendResponse(this.contingencie, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.sendResponse(this.contingencie, "rejected", "REJEITAR", "text-red");
    },
    justify() {
      this.sendResponse(
        this.contingencie,
        "pending",
        "TRANSFERIR",
        "text-orange text-bold"
      );
    },
    cancel() {
      this.sendConfirmationTransfer(
        this.contingencie,
        "canceled",
        "TRANSFERÊNCIA DE SALDO",
        "text-blue-8 text-bold"
      );
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> a Contingência ${request.contingencie}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          if (response !== "pending" && request.level === "01") {
            this.$refs.JustifiedContingencie.open(
              request,
              response,
              text,
              classMessage
            );
          } else {
            try {
              await this.approvesRejectsJustifysContingencies({
                request,
                response,
                reason: ""
              });
              this.setNotification({
                message:
                  request.level === "01"
                    ? "Aguardando a realização da TRANSFERÊNCIA no Protheus."
                    : "Resposta enviado com sucesso.",
                color: request.level === "01" ? "orange" : "green",
                position: "top"
              });
              this.$router.push({ name: "contingencies" });
            } catch (error) {
              this.setNotification({
                message: error.message,
                color: "red",
                position: "top"
              });
            }
          }
        });
    },
    sendConfirmationTransfer(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `A <span class="${classMessage}">${text}</span> referente a Contingência ${request.contingencie} já foi finalizada no Protheus?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            await this.approvesRejectsJustifysContingencies({
              request,
              response,
              reason: ""
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "contingencies" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    }
  },
  async created() {
    try {
      this.contingencie = await this.getContingencieById(this.contingencieId);
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.ContingencieDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
