<template>
  <div>
    <div v-if="data" class="row justify-center q-ma-md">
      <q-card class="col-xs-12 col-sm-3 q-ma-sm" style="width: 80%">
        <q-card-section class="q-pa-none">
          <div class="text-h6 text-center">
            {{ `${data.documentNumber} - ${data.type}` }}
          </div>
        </q-card-section>
        <q-separator size="1px" color="black" />
        <q-card-section>
          <div class="col-12 row justify-between">
            <span class="text-bold">Contingência: </span>
            <span>{{ `${data.contingencie}` }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Conta Orçamentária: </span>
            <span>{{ `${data.co} - ${data.coName}` }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Classe Orçamentária: </span>
            <span>{{ `${data.classe} - ${data.classeName}` }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Solicitante: </span>
            <span>{{ `${request}` }}</span>
          </div>
          <div class="col-12 row justify-between text-red">
            <span class="text-bold">Vlr.Contingência: </span>
            <span>{{ formatStg(data.value, "currency") }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Vlr. Empenhado: </span>
            <span>{{ formatStg(data.reserved, "currency") }}</span>
          </div>
          <div class="col-12 row justify-between">
            <span class="text-bold">Valor Total: </span>
            <span>{{ formatStg(data.value + data.reserved, "currency") }}</span>
          </div>
          <div class="col-12 row justify-between" v-show="data.level === '02'">
            <span class="text-bold">Justificativa: </span>
            <span>{{ data.reason }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import formatString from "../../helpers/formatString";
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: false,
      default: "BRL"
    }
  },
  methods: {
    formatStg(val, format) {
      return formatString(val, format);
    }
  },
  computed: {
    request() {
      const arrHierarchy = this.data.approvalHierarchy;
      var nameRequest;
      arrHierarchy.forEach(lvl => {
        if (lvl.level === "01") {
          nameRequest = lvl.approver;
        }
      });

      return nameRequest;
    }
  }
};
</script>

<style></style>
